import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  Table,
  Tabs,
  Tab,
} from 'react-bootstrap';
import axios from 'axios';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';

function Timelapse() {
  const [timelapseIndex, setIndex] = useState({});
  const [monthIndex, setMonthIndex] = useState({});
  const { id } = useParams();

  useEffect(() => {
    function getIndex() {
      axios
        .get(`https://sonar.logic.io/wcwx2/timelapse/index.json`)
        .then((res) => {
          let data = res.data;
          let newState = data.map((e) => e); // map your state here
          setIndex(newState);
        });
    }

    function getMonth(id) {
      setMonthIndex({});
      axios
        .get(`https://sonar.logic.io/wcwx2/timelapse/${id}.json`)
        .then((res) => {
          let data = res.data;
          let newState = data.map((e) => e); // map your state here
          setMonthIndex(newState);
        });
    }

    getIndex();

    if (typeof id !== 'undefined' && id != null) {
      getMonth(id);
    }

    return () => {};
  }, [id]);

  return (
    <Container fluid="md" className="mt-3" style={{ marginBottom: 100 }}>
      <h2>Time Lapse</h2>
      <Row>
        <Col>
          <ul>
            <li>
              Each morning at 4:00AM Mountain Time a time lapse video is
              generated for the previous day.
            </li>
            <li>
              Time lapses are created by capturing 1 picture every minute, 24
              hours a day and combining them in to a video.
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container>
            <Tabs defaultActiveKey="daily" id="uncontrolled-tab-example">
              <Tab eventKey="daily" title="Daily">
                <Daily
                  timelapseIndex={timelapseIndex}
                  monthIndex={monthIndex}
                />
              </Tab>
              <Tab eventKey="special" title="Special">
                <Special />
              </Tab>
            </Tabs>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

function Daily({ timelapseIndex, monthIndex }) {
  return (
    <>
      {' '}
      <Row className="mt-3">
        <Col>
          <YearTable years={timelapseIndex} />
        </Col>
      </Row>
      <Row>
        {Object.keys(monthIndex).length > 0 &&
          Object.keys(monthIndex)
            .reverse()
            .map((key, i) => (
              <Preview
                key={monthIndex[key].year + '' + i}
                data={monthIndex[key]}
              />
            ))}
      </Row>
    </>
  );
}

function Special() {
  return (
    <Table responsive bordered className="mt-3">
      <thead>
        <tr>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <SpecialVideo
          description="June 3rd 2019 to Aug 19, 2020 (only daylight hours)"
          url="https://photos.willowcreeknm.com/oneyear_01.mp4"
        />
      </tbody>
    </Table>
  );
}

function YearTable({ years }) {
  return (
    <Table responsive bordered>
      <thead>
        <tr>
          <th>Year</th>
          <th colSpan={12}>Click on a month</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(years).length > 0 &&
          Object.keys(years).map((key, i) => (
            <YearRow
              key={i}
              year={years[key].year}
              months={years[key].months}
            />
          ))}
      </tbody>
    </Table>
  );
}

function YearRow({ year, months }) {
  const monthsList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <tr key={year}>
      <td key={year}>
        <strong>{year}</strong>
      </td>
      {monthsList.map((shortMonth, i) => (
        <td key={i}>
          {months[shortMonth] && months[shortMonth].file ? (
            <Link
              to={`/timelapses/${months[shortMonth].file.replace(
                '.json',
                '',
              )}/`}
            >
              {shortMonth}
            </Link>
          ) : (
            '--'
          )}
        </td>
      ))}
    </tr>
  );
}

function Preview({ data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Card style={{ width: '28rem' }} className="m-1">
        <LazyLoadComponent>
          <Card.Img
            variant="top"
            src={`https://photos.willowcreeknm.com/webcam01-preview/${data.preview}`}
          />
        </LazyLoadComponent>
        <Card.Body>
          <Card.Text>
            {data.title}
            <Button
              variant="primary"
              onClick={handleShow}
              className="float-right"
            >
              Watch
            </Button>
            <VideoModal show={show} handleClose={handleClose} data={data} />
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

function VideoModal({ show, handleClose, data }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Player
          fluid
          autoPlay
          poster={`https://photos.willowcreeknm.com/webcam01-preview/${data.preview}`}
          src={`https://photos.willowcreeknm.com/webcam01/${data.video}`}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function SpecialVideo({ description, url }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <tr>
      <td>
        <Button variant="primary" onClick={handleShow}>
          Watch
        </Button>{' '}
        {description}
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Player fluid autoPlay src={url} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </td>
    </tr>
  );
}

export default Timelapse;
