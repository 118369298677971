import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

interface Status {
  soc: number;
  tin: number;
}

function AppFooter(): JSX.Element {
  const [status, setStatus] = useState<Status | null>(null);
  const statusId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    function getStatus() {
      axios
        .get(`https://sonar.logic.io/wcwx2/status.json?${new Date().getTime()}`)
        .then((res) => {
          const status = res.data as Status;
          setStatus(status);
        });
    }

    getStatus();
    statusId.current = setInterval(getStatus, 30000); // runs every 30 seconds.

    return () => {
      if (statusId.current) {
        clearInterval(statusId.current);
      }
    };
  }, []);

  return (
    <div className="footer pl-4">
      {status ? (
        <>
          {status.soc} / {status.tin}
        </>
      ) : (
        '--'
      )}
      <div style={{ float: 'right' }}>
        <a href="mailto:webmaster@willowcreeknm.com">
          webmaster@willowcreeknm.com
        </a>
        {'   '}
      </div>
    </div>
  );
}

export default AppFooter;
