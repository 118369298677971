import React from 'react';
import { Container } from 'react-bootstrap';
import { WildfiresNew, WildfiresHistoric } from './wx';

function WildfireHistory(): JSX.Element {
  return (
    <Container fluid="md" className="mt-3" style={{ marginBottom: 100 }}>
      <WildfiresNew showMore={false} />
      <WildfiresHistoric />
    </Container>
  );
}

export default WildfireHistory;
