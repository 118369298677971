import { Link } from 'react-router-dom';
import 'gridjs/dist/theme/mermaid.css';
import { Wildfire } from './WildfiresYear';

interface WildfiresRowHistoricProps {
  rowKey: string;
  data: Wildfire;
}

function WildfiresRowHistoric({ rowKey, data }: WildfiresRowHistoricProps) {
  return (
    <tr key={rowKey}>
      <td className="text-center">
        <small>{data.num}</small>
      </td>
      <td>
        <strong>{data.name}</strong>{' '}
        {data.map !== '' && (
          <small>
            <Link to={data.map} target="_blank" rel="noopener noreferrer">
              <strong>Map</strong>
            </Link>
          </small>
        )}
      </td>
      <td className="text-center">
        <small>
          {data.distance} {data.direction}
        </small>
      </td>
      <td className="text-center">
        <small>{data.started}</small>
      </td>
      <td className="text-center">
        <small>{data.outdate}</small>
      </td>
      <td className="text-center">
        <small>
          {data.cause_full ? <>{data.cause_full}</> : <>{data.cause}</>}
        </small>
      </td>
      <td className="text-center">
        <small>{data.size}</small>
      </td>
      <td className="text-center">
        <small>{data.cost}</small>
      </td>
    </tr>
  );
}

export default WildfiresRowHistoric;
