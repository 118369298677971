import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

function Forecast(): JSX.Element {
  return (
    <Container fluid="md" className="mt-3" style={{ marginBottom: 100 }}>
      <h2>48-Hour Forecast</h2>
      <Row>
        <Col>
          <ul>
            <li>Weather data for grid: 33.38°N, −108.63°W</li>
            <li>Provided by NWS</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Image
            src="https://sonar.logic.io/wcwx/latest_forecast.png"
            rounded
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Forecast;
