import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Image /*Alert, Button*/ } from 'react-bootstrap';
import { Current, Rain, Lunar, WildfiresNew } from './wx';
import axios from 'axios';
import { CurrentProps, defaultCurrentProps } from './wx/Current';
import { LunarProps, defaultLunarProps } from './wx/Lunar';

function Home() {
  const webcam01_url = 'https://photos.willowcreeknm.com/webcam01/latest.jpg';
  const currentId = useRef<NodeJS.Timeout | null>(null);
  const webcamId = useRef<NodeJS.Timeout | null>(null);

  const [currentData, setCurrent] = useState<CurrentProps>(defaultCurrentProps);
  const [lunar, setLunar] = useState<LunarProps>(defaultLunarProps);
  const [webcam01_refresh, setWebcam01] = useState<string>('');

  useEffect(() => {
    function getCurrent() {
      axios
        .get<CurrentProps>(
          `https://sonar.logic.io/wcwx2/current.json?${new Date().getTime()}`,
        )
        .then((res) => {
          const current = res.data;
          setCurrent(current);
        });
    }

    function getWebcam() {
      setWebcam01(`${webcam01_url}?${new Date().getTime()}`);
    }

    function getLunar() {
      axios
        .get<LunarProps>(
          `https://sonar.logic.io/wcwx2/lunar.json?${new Date().getTime()}`,
        )
        .then((res) => {
          const lunar = res.data;
          setLunar(lunar);
        });
    }

    getCurrent();
    currentId.current = setInterval(getCurrent, 30000); // runs every 30 seconds.

    getWebcam();
    webcamId.current = setInterval(getWebcam, 30000); // runs every 30 seconds.

    getLunar();

    return () => {
      if (currentId.current) clearInterval(currentId.current);
      if (webcamId.current) clearInterval(webcamId.current);
    };
  }, []);

  return (
    <Container fluid="md" className="mt-3" style={{ marginBottom: 100 }}>
      {/* 
      <Row>
        <Alert variant="danger">
          June 4th, 2021 : The outdoor unit of the weather station is currently
          suffering an outage.
        </Alert>
      </Row>
      */}
      <Row>
        <Col md={7} lg={5} className="mt-1">
          <Current current={currentData} />
        </Col>
        <Col md={5} lg={7} className="mt-1">
          {' '}
          <Image src={webcam01_refresh} rounded fluid />
          {/* <br />
          <br />
          <Button variant="success" href="/live">
            <strong>
              <i class="bi bi-camera-reels-fill"></i> Test the Live Creek
              Camera!
            </strong>
          </Button> */}
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} className="mt-1">
          <WildfiresNew showMore={true} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={5} lg={4}>
          <Lunar lunar={lunar} />
        </Col>
        <Col md={4} lg={3}>
          <Rain current={currentData} />
        </Col>
        <Col md={2}> </Col>
      </Row>
    </Container>
  );
}

export default Home;
