import { useState } from 'react';
import { Container, Row, Col, Card, Tab, Tabs } from 'react-bootstrap';
import { WiFire } from 'weather-icons-react';
import 'gridjs/dist/theme/mermaid.css';
import WildfiresYear from './WildfiresYear';

function WildfiresHistoric() {
  const thisYear = new Date().getFullYear();
  const wildfireYears = getKeys();
  const [fireYear, setYear] = useState(thisYear);

  function getKeys() {
    let yearKeys = [];

    for (var i = thisYear; i >= 2014; i--) {
      yearKeys.push(i);
    }

    return yearKeys;
  }

  return (
    <>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>
            <WiFire size={36} color="#000" /> {fireYear} Gila Wildfires
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <small className="text-muted">
              Distance represents miles and direction from Willow Creek to where
              the fire started.
            </small>
          </Card.Subtitle>
          <Container className="p-0">
            <Row className="p-0">
              <Col className="p-30">
                <Tabs
                  className="mb-3"
                  defaultActiveKey={thisYear}
                  variant="pills"
                  onSelect={(eventKey: string | null) => {
                    if (eventKey !== null) {
                      setYear(parseInt(eventKey));
                    }
                  }}
                  mountOnEnter
                >
                  {wildfireYears.map((key, index) => (
                    <Tab key={index} eventKey={key} title={key}>
                      <Row className="p-0">
                        <WildfiresYear startYear={key} />
                      </Row>
                    </Tab>
                  ))}
                </Tabs>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
}

export default WildfiresHistoric;
