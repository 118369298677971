import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { WiFire } from 'weather-icons-react';
import TimeAgo from 'react-timeago';
import axios from 'axios';

interface Wildfire {
  name: string;
  distance: number;
  direction: string;
  started: Date;
  cause: string;
  updated: Date;
  size: string;
  contained: string;
  map: string;
  inciweb: string;
  agency_type: string;
  personnel: string;
  incident_Type: string;
  activity: string;
  cost: string;
}

function WildfiresNew(props: { showMore: boolean }) {
  const wildfireId = useRef<number | null>(null);
  const [wildfireData, setWildfires] = useState([]);

  useEffect(() => {
    function getWildfires() {
      axios
        .get(
          `https://sonar.logic.io/wcwx2/wildfires-active.json?${new Date().getTime()}`,
        )
        .then((res) => {
          const current = res.data;
          setWildfires(current);
        });
    }

    getWildfires();
    wildfireId.current = window.setInterval(getWildfires, 90000);

    return () => {
      if (wildfireId.current !== null) {
        window.clearInterval(wildfireId.current);
      }
    };
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <WiFire size={36} color="#000" /> Active Gila Wildfires{' '}
          {props.showMore === true && (
            <small>
              {' '}
              -{' '}
              <Link to={{ pathname: '/wildfires' }}>
                <strong>More Fire Information</strong>
              </Link>
            </small>
          )}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <small className="text-muted">
            Distance represents miles and direction from Willow Creek to where
            the fire started.
          </small>
        </Card.Subtitle>
        <Container className="p-0">
          <Row className="p-0">
            <Col className="p-0">
              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <small>Name</small>
                    </th>
                    <th className="text-center">
                      <small>Distance (Miles)</small>
                    </th>
                    <th className="text-left" colSpan={4}>
                      <small>Information</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {wildfireData.map((data: Wildfire) => (
                    <WildfireRow key={data.name} data={data} showMore={false} />
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

interface WildfireRowProps {
  data: Wildfire;
  showMore: boolean;
}

function WildfireRow({ data, showMore }: WildfireRowProps) {
  // const {
  //   name,
  //   acres,
  //   percentContained,
  //   is_active,
  //   is_cal_fire_incident,
  //   longitude,
  //   latitude,
  //   inciweb,
  //   map,
  // } = data;

  return (
    <React.Fragment>
      {data.name === 'No active files at this time!' ? (
        <tr>
          <td colSpan={3} align="center">
            No active fires
          </td>
        </tr>
      ) : (
        <React.Fragment>
          <tr>
            <td
              rowSpan={showMore === true ? 4 : 5}
              style={{ borderTopWidth: '3px', borderTopColor: '#6b6a67' }}
            >
              <strong>{data.name}</strong>
              {data.inciweb !== '' && (
                <div>
                  <small>
                    <Link
                      to={{ pathname: data.inciweb }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>Inciweb</strong>
                    </Link>
                  </small>
                </div>
              )}
              {data.map !== '' && (
                <div>
                  <small>
                    <Link
                      to={{ pathname: data.map }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>Map</strong>
                    </Link>
                  </small>
                </div>
              )}
            </td>
            <td
              className="text-center"
              rowSpan={showMore === true ? 4 : 5}
              style={{ borderTopWidth: '3px', borderTopColor: '#6b6a67' }}
            >
              <small>
                <small>
                  {data.distance} {data.direction}
                </small>
              </small>
            </td>
            <td
              className="text-left"
              style={{ borderTopWidth: '3px', borderTopColor: '#6b6a67' }}
            >
              <small className="text-muted">Discovered</small>
            </td>
            <td style={{ borderTopWidth: '3px', borderTopColor: '#6b6a67' }}>
              <small>{data.started.toLocaleString()}</small>
            </td>
            <td style={{ borderTopWidth: '3px', borderTopColor: '#6b6a67' }}>
              <small className="text-muted">Last Update</small>
            </td>
            <td style={{ borderTopWidth: '3px', borderTopColor: '#6b6a67' }}>
              <small>
                {' '}
                <TimeAgo date={data.updated} />
              </small>
            </td>
          </tr>
          <tr>
            <td>
              <small className="text-muted">Cause</small>
            </td>
            <td>
              <small>{data.cause}</small>
            </td>
            <td>
              <small className="text-muted">Size</small>
            </td>
            <td>
              <small>{data.size}</small>
            </td>
          </tr>
          <tr>
            <td>
              <small className="text-muted">Agency/Incident</small>
            </td>
            <td>
              {data.agency_type !== '' ? (
                <small>
                  {data.agency_type} / {data.incident_Type}
                </small>
              ) : (
                <small>-</small>
              )}
            </td>
            <td>
              <small className="text-muted">Contained</small>
            </td>
            <td>
              <small>{data.contained}</small>
            </td>
          </tr>
          <tr>
            <td>
              <small className="text-muted">Total Personnel</small>
            </td>
            <td>
              {data.personnel !== '' ? (
                <small>{data.personnel}</small>
              ) : (
                <small>-</small>
              )}
            </td>
            <td>
              <small className="text-muted">Fire Activity</small>
            </td>
            <td>
              {data.activity !== '' ? (
                <small>{data.activity}</small>
              ) : (
                <small>-</small>
              )}
            </td>
          </tr>
          {showMore !== true && (
            <tr>
              <td>
                <small className="text-muted">Estimated Cost</small>
              </td>
              <td colSpan={3}>
                <small>{data.cost}</small>
              </td>
            </tr>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default WildfiresNew;
