import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import TimeAgo from 'react-timeago';

interface CurrentProps {
  last_update: Date;
  humidity: number;
  dewPoint: number;
  baromrelin: number;
  uv: number;
  hourlyrainin: number;
  tempf: number;
  feelsLike: number;
  wind_mph: number;
  wind_dir: string;
  gust_mph: number;
  winddiricon: string;
  winddir2: string;
  windspeedmph: number;
  windgustmph: number;
  maxdailygust: number;
  dailyrainin: number;
  solarradiation: number;
  lastRain: Date;
  weeklyrainin: number;
  monthlyrainin: number;
  temp: {
    high: {
      value: number;
    };
    low: {
      value: number;
    };
  };
}

export const defaultCurrentProps: CurrentProps = {
  last_update: new Date(),
  humidity: 0,
  dewPoint: 0,
  baromrelin: 0,
  uv: 0,
  hourlyrainin: 0,
  tempf: 0,
  feelsLike: 0,
  wind_mph: 0,
  wind_dir: '',
  gust_mph: 0,
  winddiricon: '',
  winddir2: '',
  windspeedmph: 0,
  windgustmph: 0,
  maxdailygust: 0,
  dailyrainin: 0,
  solarradiation: 0,
  lastRain: new Date(),
  weeklyrainin: 0,
  monthlyrainin: 0,
  temp: {
    high: {
      value: 0,
    },
    low: {
      value: 0,
    },
  },
};

export type { CurrentProps };

const Current: React.FC<{ current: CurrentProps }> = ({ current }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <CheckCircleFill size={24} color="#000" /> Current Conditions
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {' '}
          <small className="text-muted ml-4">
            Last updated{' '}
            {current &&
              current.last_update &&
              (current.last_update,
              (<TimeAgo date={current.last_update} live />))}
          </small>
        </Card.Subtitle>

        <Container>
          <Row className="pb-2">
            <Col>
              <Temperatures current={current} />
            </Col>
            <Col>
              <Wind current={current} />
            </Col>
          </Row>
          <Row className="text-center pt-3 border-top border-dark">
            <Col>
              <small className="text-muted">HUMIDITY</small>
              <br />
              <h6>{current.humidity}%</h6>
            </Col>
            <Col>
              <small className="text-muted">DEW POINT</small>
              <br />
              <h6>
                {current.dewPoint}
                {'\u00b0'}
              </h6>
            </Col>
            <Col>
              <small className="text-muted">PRESSURE</small>
              <br />
              <h6>{current.baromrelin}in</h6>
            </Col>
          </Row>
          <Row className="text-center pt-3">
            <Col>
              <small className="text-muted">UV</small>
              <br />
              <h6>{current.uv}</h6>
            </Col>
            <Col>
              <small className="text-muted">PRECIP RATE</small>
              <br />
              <h6>{current.hourlyrainin} in/hr</h6>
            </Col>
            <Col>
              <small className="text-muted">PRECIP ACCUM</small>
              <br />
              <h6>{current.dailyrainin} in</h6>
            </Col>
          </Row>
          <Row className="text-center pt-3">
            <Col>
              <small className="text-muted">SOLAR RADIATION</small>
              <br />
              <h6>{current.solarradiation} w/m²</h6>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

const Temperatures: React.FC<{ current: CurrentProps }> = ({ current }) => {
  return (
    <Container className="p-0">
      <Row>
        <Col>
          <Row className="text-center">
            <Col>
              <h1 className="mb-0">
                {current.tempf}
                {'\u00b0'}
              </h1>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <small className="text-muted">
                Feels like {current.feelsLike}
                {'\u00b0'}
              </small>
            </Col>
          </Row>
        </Col>
        <Col className="m-0 p-0">
          <Row className="text-center">
            <Col>
              <h6 className="text-success mb-0">
                {' '}
                {current && current.temp ? current.temp.high.value : '--'}
                {'\u00b0'}
              </h6>
              <small className="text-muted">High</small>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <h6 className="text-info mb-0">
                {current && current.temp ? current.temp.low.value : '--'}
                {'\u00b0'}
              </h6>
              <small className="text-muted">Low</small>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const Wind: React.FC<{ current: CurrentProps }> = ({ current }) => {
  return (
    <Container>
      <Row className="text-center">
        <Col>
          {current &&
            current.winddiricon &&
            (current.winddiricon,
            (
              <i
                style={{ fontSize: 50 }}
                className={`wi wi-wind wi-towards-${current.winddiricon}`}
              />
            ))}
          <br />
          <h6>{current.winddir2}</h6>
        </Col>
      </Row>
      <Row className="text-center">
        <Col>
          <strong>{current.windspeedmph}</strong>
          <br />
          <small className="text-muted">Wind</small>
        </Col>
        <Col>
          <strong>{current.windgustmph}</strong>
          <br />
          <small className="text-muted">Gust</small>
        </Col>
        <Col>
          <strong>{current.maxdailygust}</strong>
          <br />
          <small className="text-muted">Today's Peak</small>
        </Col>
      </Row>
    </Container>
  );
};

export default Current;
