import { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import 'gridjs/dist/theme/mermaid.css';
import axios from 'axios';
import WildfiresRowHistoric from './WildfiresRowHistoric';

interface WildfiresYearProps {
  startYear: number;
}

export interface Wildfire {
  num: number;
  name: string;
  map: string;
  distance: number;
  direction: string;
  started: string;
  outdate: string;
  cause: string;
  cause_full: string;
  size: string;
  cost: string;
}

function WildfiresYear({ startYear }: WildfiresYearProps) {
  const [wildfireDataHistory, setWildfiresData] = useState([]);

  useEffect(() => {
    function getWildfireYear() {
      axios
        .get(
          `https://sonar.logic.io/wcwx2/wildfires-${startYear}.json?${new Date().getTime()}`,
        )
        .then((res) => {
          const current = res.data;
          setWildfiresData(current);
        });
    }

    getWildfireYear();
  }, [startYear]);

  return (
    <Table striped hover size="sm">
      <thead>
        <tr>
          <th className="text-center">
            <small>#</small>
          </th>
          <th>
            <small>Name</small>
          </th>
          <th className="text-center">
            <small>Distance (Miles)</small>
          </th>
          <th className="text-center">
            <small>Discovered</small>
          </th>
          <th className="text-center">
            <small>Contained</small>
          </th>
          <th className="text-center">
            <small>Cause</small>
          </th>
          <th className="text-center">
            <small>Size</small>
          </th>
          <th className="text-center">
            <small>
              Estimated<br></br>Cost
            </small>
          </th>
        </tr>
      </thead>
      <tbody>
        {wildfireDataHistory.map((wildfire, index) => (
          <WildfiresRowHistoric
            key={index}
            rowKey={startYear.toString()}
            data={wildfire}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default WildfiresYear;
