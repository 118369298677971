import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';
import { Cloud } from 'react-bootstrap-icons';
import AppFooter from './AppFooter';
// import { createBrowserHistory } from 'history';
import './App.css';

import Home from './Home';
import Timelapse from './Timelapse';
import Forecast48 from './Forecast48';
import WildfireHistory from './WildfireHistory';

// const history = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Navbar bg="dark" variant="dark" collapseOnSelect expand="md">
            <Navbar.Brand href="/">
              <Cloud /> Willow Creek WX
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/timelapses">
                  <Nav.Link>Time-lapse</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/forecast">
                  <Nav.Link>48-Hour Forecast</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/wildfires">
                  <Nav.Link>Wildfires</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes>
            <Route path="/forecast" element={<Forecast48 />} />
            <Route path="/wildfires" element={<WildfireHistory />} />
            <Route path="/timelapses/:id/" element={<Timelapse />} />
            <Route path="/timelapses" element={<Timelapse />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
        <AppFooter />
      </>
    );
  }
}

export default App;
