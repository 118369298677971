import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Moon } from 'react-bootstrap-icons';

interface LunarProps {
  moonphase: string;
  moonnew: Date;
  moonfull: Date;
  moonrise: string;
  moonset: string;
  moonfirst: Date;
  moonlast: Date;
  nextnew: Date;
  nextfull: Date;
  prevnew: Date;
  prevfull: Date;
  sunrise: Date;
  sunset: Date;
  sunfirstlight: Date;
  sunlastlight: Date;
  daylight: string;
}

export const defaultLunarProps: LunarProps = {
  moonphase: '',
  moonnew: new Date(),
  moonfull: new Date(),
  moonrise: '',
  moonset: '',
  moonfirst: new Date(),
  moonlast: new Date(),
  nextnew: new Date(),
  nextfull: new Date(),
  prevnew: new Date(),
  prevfull: new Date(),
  sunrise: new Date(),
  sunset: new Date(),
  sunfirstlight: new Date(),
  sunlastlight: new Date(),
  daylight: '',
};

export type { LunarProps };

const Lunar: React.FC<{ lunar: LunarProps }> = ({ lunar }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Moon size={24} color="#000" /> Sun &amp; Moon
        </Card.Title>

        <Row className="text-center pt-3">
          <Col>
            <small className="text-muted">DAYLIGHT</small>
            <br />
            <small>{lunar.daylight.toString()}</small>
          </Col>
        </Row>

        <Row className="text-center pt-3">
          <Col>
            <small className="text-muted">FIRST LIGHT</small>
            <br />
            <small>{lunar.sunfirstlight.toString()}</small>
          </Col>
          <Col>
            <small className="text-muted">LAST LIGHT</small>
            <br />
            <small>{lunar.sunlastlight.toString()}</small>
          </Col>
        </Row>
        <Row className="text-center pt-3 pb-3">
          <Col>
            <small className="text-muted">SUNRISE</small>
            <br />
            <small>{lunar.sunrise.toString()}</small>
          </Col>
          <Col>
            <small className="text-muted">SUNSET</small>
            <br />
            <small>{lunar.sunset.toString()}</small>
          </Col>
        </Row>

        <Row className="text-center pt-3 border-top border-light">
          <Col>
            <small className="text-muted">MOONRISE</small>
            <br />
            <small>{lunar.moonrise.toString()}</small>
          </Col>
          <Col>
            <small className="text-muted">NEXT NEW</small>
            <br />
            <small>{lunar.moonnew.toString()}</small>
          </Col>
        </Row>
        <Row className="text-center pt-3">
          <Col>
            <small className="text-muted">MOONSET</small>
            <br />
            <small>{lunar.moonset}</small>
          </Col>
          <Col>
            <small className="text-muted">NEXT FULL</small>
            <br />
            <small>{lunar.moonfull.toString()}</small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Lunar;
