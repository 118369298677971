import React from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { WiShowers } from 'weather-icons-react';
import TimeAgo from 'react-timeago';

interface Current {
  lastRain: Date;
  dailyrainin: number;
  weeklyrainin: number;
  monthlyrainin: number;
}

interface Props {
  current: Current;
}

const Rain: React.FC<Props> = ({ current }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <WiShowers size={36} color="#000" /> Rainfall
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <small className="text-muted">
            Last rain{' '}
            {current &&
              current.lastRain &&
              (current.lastRain, (<TimeAgo date={current.lastRain} />))}
          </small>
        </Card.Subtitle>
        <Container className="p-0">
          <Row>
            <Col>
              <small>Today</small>
            </Col>
            <Col className="text-center">
              <small className="text-muted">{current.dailyrainin}in</small>
            </Col>
          </Row>
          <Row>
            <Col>
              <small>Week</small>
            </Col>
            <Col className="text-center">
              <small className="text-muted">{current.weeklyrainin}in</small>
            </Col>
          </Row>
          <Row>
            <Col>
              <small>Month</small>
            </Col>
            <Col className="text-center">
              <small className="text-muted">{current.monthlyrainin}in</small>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};
export default Rain;
