import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { WiFire } from 'weather-icons-react';
import TimeAgo from 'react-timeago';
import axios from 'axios';

interface Wildfire {
  name: string;
  distance: number;
  direction: string;
  started: Date;
  cause: string;
  updated: Date;
  size: string;
  contained: string;
}

function Wildfires() {
  const wildfireId = useRef<NodeJS.Timeout | null>(null);
  const [wildfireData, setWildfires] = useState<Record<string, Wildfire>>({});

  useEffect(() => {
    function getWildfires() {
      axios
        .get(
          `https://sonar.logic.io/wcwx2/wildfires-active.json?${new Date().getTime()}`,
        )
        .then((res) => {
          const current = res.data;
          setWildfires(current);
        });
    }

    getWildfires();
    wildfireId.current = setInterval(getWildfires, 90000);

    return () => {
      if (wildfireId.current) {
        clearInterval(wildfireId.current);
      }
    };
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <WiFire size={36} color="#000" /> Gila Wildfires
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <small className="text-muted">
            Distance represents miles and direction from Willow Creek to where
            the fire started.
          </small>
        </Card.Subtitle>
        <Container className="p-0">
          <Row className="p-0">
            <Col className="p-0">
              <Table hover size="sm">
                <thead>
                  <tr>
                    <th>
                      <small>Name</small>
                    </th>
                    <th className="text-center">
                      <small>Distance (Miles)</small>
                    </th>
                    <th className="text-center">
                      <small>Discovered/Cause</small>
                    </th>
                    <th className="text-center">
                      <small>Updated</small>
                    </th>
                    <th className="text-center">
                      <small>Size</small>
                    </th>
                    <th className="text-center">
                      <small>Contained</small>
                    </th>
                    <th className="text-center">
                      <small>More Info</small>
                    </th>
                    <th className="text-center">
                      <small>Map</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(wildfireData).length > 0 &&
                    Object.keys(wildfireData).map((key, i) => (
                      <WildfireRow key={i} data={wildfireData[key]} />
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

function WildfireRow({ data }: { data: Wildfire }) {
  return (
    <tr>
      <td>
        <small>{data.name}</small>
      </td>
      <td className="text-center">
        <small>
          <small>
            {data.distance} {data.direction}
          </small>
        </small>
      </td>
      <td className="text-center">
        <small>
          {data.started.toLocaleString()}
          <br />
          <small className="text-muted">{data.cause}</small>
        </small>
      </td>
      <td className="text-center">
        <TimeAgo date={data.updated} live />
      </td>
      <td className="text-center">
        <small>{data.size}</small>
      </td>
      <td className="text-center">
        <small>{data.contained}</small>
      </td>
      <td className="text-center">
        <Link to={`/wildfires/${data.name}`}>More Info</Link>
      </td>
      <td className="text-center">
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${data.name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Map
        </a>
      </td>
    </tr>
  );
}

export default Wildfires;
